import { Card, LoadingBuzz } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";
import { useAppHeader } from "admin-portal-shared-services";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useEnvContext } from "../../components";

export const CardTextArea = styled(Card, {
  padding: "25px",
});

const ExamplePage = (): JSX.Element => {
  const env = useEnvContext();
  const { formatMessage } = useIntl();
  const [_, setAppHeaderConfig] = useAppHeader();

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: "Customer Release Management",
      breadcrumbConfig: {
        homePath: "/customer-release-management",
        items: [
          {
            label: "Customer Release Management",
            path: "/customer-release-management",
            isCurrentPage: true,
          },
        ],
      },
    });
  }, [setAppHeaderConfig]);

  return (
    <div>
      <CardTextArea title="Example Card" elevated={"small"} border={"small"}>
        <h2>Hey!</h2>
        <LoadingBuzz />
      </CardTextArea>
    </div>
  );
};

export default ExamplePage;
