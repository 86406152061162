import { Grid } from "@hexa-ui/components";
import { Home, Settings } from "@hexa-ui/icons";
import { useSidebar } from "admin-portal-shared-services";
import { EnvConfig, EnvProvider } from "./components";
import { IntlProvider } from "./i18n";
import Router from "./Router";

export default function App(props: EnvConfig) {
  useSidebar({
    items: [
      {
        id: "1",
        title: "Home",
        icon: () => <Home />,
        path: "/customer-release-management",
        onPageChange: () => {},
      },
      {
        id: "2",
        title: "Settings",
        icon: () => <Settings />,
        path: "/customer-release-management/home",
      },
    ],
    utils: [],
  });

  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <Grid.Container type="fluid" sidebar>
          <div style={{ width: "100%" }}>
            <Router />
          </div>
        </Grid.Container>
      </IntlProvider>
    </EnvProvider>
  );
}
