import { useAppHeader } from "admin-portal-shared-services";
import { useEffect } from "react";

const HomePage = (): JSX.Element => {
  const [_, setAppHeaderConfig] = useAppHeader();

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: "Customer Release Management - Home",
      breadcrumbConfig: {
        homePath: "customer-release-management",
        items: [
          {
            label: "Customer Release Management - Home",
            path: "customer-release-management/home",
            isCurrentPage: true,
          },
        ],
      },
    });
  }, [setAppHeaderConfig]);

  return <div>Yo!</div>;
};

export default HomePage;
