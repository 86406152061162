import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ExamplePage from "./pages/ExamplePage/ExamplePage";
import HomePage from "./pages/home/HomePage";

const BASENAME = "customer-release-management";
const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`/`}
          element={
            <Suspense fallback={<p>loading</p>}>
              <ExamplePage />
            </Suspense>
          }
        />
        <Route
          path={`/${BASENAME}`}
          element={
            <Suspense fallback={<p>loading</p>}>
              <ExamplePage />
            </Suspense>
          }
        />
        <Route
          path={`/${BASENAME}/home`}
          element={
            <Suspense fallback={<p>loading</p>}>
              <HomePage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
